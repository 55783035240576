import { css, keyframes } from "@emotion/react";
import { colors, spacing, fontSizes, lineHeights } from "../../../core/styles";
import { glassIcon, arrowIcon, loadingSpinner, jumpingArrow, iconClipper } from "./SearchDefault.style";

const fadeIn = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1 }
`;
const searchBarAnimation = keyframes`
    0% { width: 0%; }
    100% { width: 100%; }
`;

export const searchSquare = {
  searchIconWrapper: () =>
    css({
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      position: "absolute",
      right: "14px",
      padding: "12px",
      height: "40px",
      width: "48px",
      path: {
        fill: colors.linkBlue.hex,
      },
      "&:disabled": {
        cursor: "unset",
        polyline: {
          fill: colors.disabledGrey.hex,
        },
      },
    }),

  form: () =>
    css({
      maxWidth: "700px",
      margin: `auto`,
      position: "relative",
      transform: `translateX(-29px)`,
    }),

  placeholderStyles: (clicked: boolean) =>
    css({
      color: colors.stdTextGrey.hex,
      position: `absolute`,
      top: `12px`,
      left: "13px",
      opacity: 0,
      animation: `${fadeIn} .3s ease`,
      animationDelay: `.3s`,
      animationFillMode: `forwards`,
      display: clicked && `none`,
      pointerEvents: "none",
    }),

  errorWrapper: () =>
    css({
      width: `calc(100% - ${spacing.baseUnitMobile4})`,
      margin: "auto",
      position: "absolute",
      right: 0,
      left: 0,
    }),

  input: (hasError: boolean, clicked: boolean) =>
    css({
      textTransform: "uppercase",
      border: `1px solid`,
      borderColor: hasError ? colors.red.hex : colors.linkBlue.hex,
      borderRadius: "24px",
      height: "45px",
      backgroundColor: colors.white.hex,
      padding: spacing.baseUnitMobile1,
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.cat,
      color: hasError ? colors.red.hex : colors.headlineGrey.hex,
      width: "100%",
      outline: "none",
      animation: `${searchBarAnimation} .3s ease`,
      float: "right",
      transition: "all .3s ease",
      "::placeholder": {
        color: colors.stdTextGrey.hex,
        fontStyle: "italic",
        visibility: clicked ? "visible" : "hidden",
        textTransform: "none",
      },
      "::-moz-placeholder": {
        opacity: clicked ? 1 : 0,
      },
      "&:focus": {
        boxShadow: " 0 0 10px 0 rgba(0,0,0,0.3)",
      },
    }),
  iconClipper: iconClipper,
  jumpingArrow: jumpingArrow,
  glassIcon: glassIcon,
  arrowIcon: arrowIcon,
  loadingSpinner: loadingSpinner,
};
